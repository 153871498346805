<template>
  <b-card-code title="">
    <div class="row mb-2">
      <div class="col-lg-6">
        <h4>Járművek listája</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/cars/create')">Jármű létrehozás</button>
      </div>
      <div class="col-lg-12 mt-2 text-right">
        <b-badge variant="primary" class="mr-1">Összes jármű {{ rows.length }} db</b-badge>
        <b-badge variant="primary" class="mr-1">Monolit Épszer jármű {{ getEmployeeNumberInCompany('Monolit Épszer Kft.') }} db</b-badge>
        <b-badge variant="primary" class="mr-1">Monolit Profi jármű {{ getEmployeeNumberInCompany('Monolit Profi Kft.') }} db</b-badge>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'active'"
          class="text-nowrap"
        >
          <span class="text-nowrap" v-if="props.row.active == 1">Aktív</span>
          <span class="text-nowrap" v-else>Inaktív</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'salary'">
          <span>{{ parseInt(props.row.salary).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="$router.push('/cars/edit/'+props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Szerkesztés</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteThisCar(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Törlés</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else-if="props.column.field === 'registration_number'">
          <span style="cursor:pointer" @click="selectedData = props.row;$bvModal.show('car_data');getData(props.row.id)">{{ props.row.registration_number }}</span>
        </span>

        <div v-else-if="props.column.field === 'service'">
          <span>{{ parseInt(props.row.service).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}</span> KM
        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="car_data" title="Jármű adatai" size="lg" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-col lg="12" class="mt-1">
          <b>Jármű adatok</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Rendszám (A): <b>{{ selectedData.registration_number }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Forgalomba helyezés ideje (B): <b>{{ selectedData.entry_into_service }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Gyártás éve: <b>{{ selectedData.production_year }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Gyártó (D.1): <b>{{ selectedData.manufacture }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Model (D.3): <b>{{ selectedData.variety }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Alvázszám (E): <b>{{ selectedData.type }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Hengerűrtartalom (P.1): <b>{{ selectedData.cylinder_capacity }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Teljesítmény (P.2): <b>{{ selectedData.power }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Üzemanyag (P.3): <b>{{ selectedData.fuel }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Motorszám (P.5): <b>{{ selectedData.engine_number }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Saját tömeg (G): <b>{{ selectedData.own_weight }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Össz tömeg (F.1): <b>{{ selectedData.total_mass }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Szín (R): <b>{{ selectedData.color }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Ülések száma (S.1): <b>{{ selectedData.seats }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Környezet védelmi besorolás (V.9): <b>{{ selectedData.environmental_classification }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Műszaki vizsga lejárata: <b>{{ selectedData.technical_validity }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Melyik céghez tartozik: <b>{{ selectedData.monolit }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Sofőr: <b>{{ selectedData.driver }}</b>
        </b-col>
        <b-col lg="12" class="mt-1">
          <hr>
          <b>Biztosítások</b>
        </b-col>
        <b-col>
          <table class="table table-responsive">
            <tr>
              <th>
                <b>Státusz</b>
              </th>
              <th>
                <b>Biztosító neve</b>
              </th>
              <th>
                <b>Biztosítás típusa</b>
              </th>
              <th>
                <b>Fizetés gyakorisága</b>
              </th>
              <th>
                <b>Biztosítás kötvényszáma</b>
              </th>
              <th>
                <b>Biztosítás ára</b>
              </th>
              <th>
                <b>Biztosítás lejárta</b>
              </th>
            </tr>
            <tr v-for="insurance_data in insurance" :key="insurance_data.id">
              <td v-if="current_date <= insurance_data.expiry_date"><span class="badge badge-success">Érvényes</span></td>
              <td v-if="current_date > insurance_data.expiry_date"><span class="badge badge-danger">Lejárt</span></td>
              <td>{{ insurance_data.insurance_name }}</td>
              <td>{{ insurance_data.insurance_type }}</td>
              <td>{{ insurance_data.insurance_frequency }}</td>
              <td>{{ insurance_data.policy_number }}</td>
              <td>{{ parseInt(insurance_data.price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</td>
              <td>{{ insurance_data.expiry_date }}</td>
            </tr>
          </table>
        </b-col>
        <b-col lg="12" class="mt-1">
          <hr>
          <b>Autópálya matrica</b>
        </b-col>
        <b-col>
          <table class="table table-responsive">
            <tr>
              <th>
                <b>Státusz</b>
              </th>
              <th>
                <b>Vásárlás dátuma</b>
              </th>
              <th>
                <b>Lejárati dátum</b>
              </th>
              <th>
                <b>Típus</b>
              </th>
              <th>
                <b>Ára (darabja)</b>
              </th>
              <th>
                <b>Autópálya matrica kategória</b>
              </th>
            </tr>
            <tr v-for="highway_sticker_data in highway_sticker" :key="highway_sticker_data.id">
              <td v-if="current_date <= highway_sticker_data.expiration_date"><span class="badge badge-success">Érvényes</span></td>
              <td v-if="current_date <= highway_sticker_data.expiration_date">{{ highway_sticker_data.buy_date }}</td>
              <td v-if="current_date <= highway_sticker_data.expiration_date">{{ highway_sticker_data.expiration_date }}</td>
              <td v-if="current_date <= highway_sticker_data.expiration_date">{{ highway_sticker_data.type }}</td>
              <td v-if="current_date <= highway_sticker_data.expiration_date">{{ parseInt(highway_sticker_data.price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}</td>
              <td v-if="current_date <= highway_sticker_data.expiration_date">{{ highway_sticker_data.category }}</td>
              <td v-if="current_date <= highway_sticker_data.expiration_date">{{ highway_sticker_data.county }}</td>
            </tr>
          </table>
        </b-col>
        <b-col lg="12" class="mt-1">
          <hr>
          <b>Szervíz</b>
        </b-col>
        <b-col>
          <table class="table table-responsive">
            <tr>
              <th>
                <b>Szervíz neve</b>
              </th>
              <th>
                <b>Számla sorszám</b>
              </th>
              <th>
                <b>Teljesítés dátuma</b>
              </th>
              <th>
                <b>Km állás</b>
              </th>
              <th>
                <b>Szervíz intervallum</b>
              </th>
              <th>
                <b>Igénybe vett szolgáltatások</b>
              </th>
            </tr>
            <tr v-for="service_data in services" :key="service_data.id">
              <td>{{ service_data.service_name }}</td>
              <td>{{ service_data.billing_number }}</td>
              <td>{{ service_data.date_of_completion }}</td>
              <td>{{ service_data.km_at_the_time_of_service }}</td>
              <td>{{ service_data.service_interval }}</td>
              <td><span class="badge badge-primary" v-for="service_name in service_data.name_of_service" :key="service_name">{{ service_name }}</span></td>
            </tr>
          </table>
        </b-col>
        <b-col lg="12" class="mt-1">
          <hr>
          <b>Autó gumi</b>
        </b-col>
        <b-col>
          <table class="table table-responsive">
            <tr>
              <th>
                <b>Évszak</b>
              </th>
              <th>
                <b>Gumi márkája</b>
              </th>
              <th>
                <b>Gumi típusa</b>
              </th>
              <th>
                <b>Kerék szélessége</b>
              </th>
              <th>
                <b>Profilaránya</b>
              </th>
              <th>
                <b>Átmérő</b>
              </th>
              <th>
                <b>Darabszám</b>
              </th>
            </tr>
            <tr v-for="wheel_data in wheel" :key="wheel_data.id">
              <td>{{ wheel_data.season }}</td>
              <td>{{ wheel_data.brand }}</td>
              <td>{{ wheel_data.model }}</td>
              <td>{{ wheel_data.size }}</td>
              <td>{{ wheel_data.profile }}</td>
              <td>{{ wheel_data.diameter }}</td>
              <td>{{ wheel_data.qty }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BCol, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      current_date: (new Date()).toISOString().split('T')[0],
      current_data: '',
      insurance: [],
      highway_sticker: [],
      services: [],
      wheel: [],
      selectedData: {},
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Rendszám',
          field: 'registration_number',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés rendszámra',
          },
        },
        {
          label: 'Márka',
          field: 'manufacture',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés márka szerint',
          },
        },
        {
          label: 'Model',
          field: 'variety',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés modelre',
          },
        },
        {
          label: 'Gyártási év',
          field: 'production_year',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés fizetésre',
          },
        },
        {
          label: 'Cég',
          field: 'monolit',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés cégre',
          },
        },
        {
          label: 'Műszaki érvényessége',
          field: 'technical_validity',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: 'Szervíz esedékes',
          field: 'service',
          type: 'number',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-success',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-info',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.loadCarList()
  },
  methods: {
    getData(id) {
      this.getInsurance(id)
      this.getHighwayStickers(id)
      this.getService(id)
      this.getWheel(id)
    },
    getService(id) {
      axios({
        method: 'get',
        url: `car-service/list/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.services = response.data
      })
    },
    getHighwayStickers(id) {
      axios({
        method: 'get',
        url: `car-highway-sticker/list/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.highway_sticker = response.data
      })
    },
    getInsurance(id) {
      axios({
        method: 'get',
        url: `car-insurance/list/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.insurance = response.data
      })
    },
    getWheel(id) {
      axios({
        method: 'get',
        url: `car-wheel/list/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.wheel = response.data
      })
    },
    deleteThisCar(id) {
      axios({
        method: 'delete',
        url: `car/delete/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadCarList()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'járműsikeresen törölve lett',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    getEmployeeNumberInCompany(company_name) {
      const rows = this.rows.filter(row => row.monolit === company_name)
      return rows.length
    },
    loadCarList() {
      axios({
        method: 'get',
        url: 'car/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
  .tooltip-inner {
    max-width: 500px;
  }
</style>
